import 'swiper/swiper-bundle.css';
import 'swiper/modules/autoplay.css';
import 'swiper/modules/pagination.min.css';
import "../css/index.css";

(() => {
	function smoothScroll(target) {
		const element = document.getElementById(target);
		if (element) {
			let yOffset = -140;

			const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
			
			window.scrollTo({
				top: y,
				behavior: 'smooth'
			});
		}
	} 
	document.addEventListener('DOMContentLoaded', function() {
		const anchors = document.querySelectorAll('a[href^="#"]');
		
		anchors.forEach(function(anchor) {
		  	anchor.addEventListener('click', function(event) {
				event.preventDefault();
				const target = anchor.getAttribute('href').substring(1);
				smoothScroll(target);
		  	});
		});
	});
})();

(() => {
	const formPopupToggleClass = 'form-popup-shown';
	const jsCloseFormPopup = document.querySelectorAll('.js-close-form-popup');
	const jsOpenFormPopup = document.querySelectorAll('.js-open-form-popup');
	const formPopup = document.getElementById('form-popup');

	if ( jsCloseFormPopup.length ) {
		jsCloseFormPopup.forEach((closeBtn) => {
			closeBtn.addEventListener('click', (e) => {
				e.preventDefault();

				formPopup.classList.remove(formPopupToggleClass);
			});
		});
	}

	if ( jsOpenFormPopup.length ) {
		jsOpenFormPopup.forEach((openBtn) => {
			openBtn.addEventListener('click', (e) => {
				e.preventDefault();

				formPopup.classList.add(formPopupToggleClass);
			});
		});
	}

	const dropArea = document.getElementById('drop-area');

    dropArea.addEventListener('dragover', (event) => {
        event.preventDefault();
        dropArea.style.borderColor = '#9A9A9A';
    });

    dropArea.addEventListener('dragleave', () => {
        dropArea.style.borderColor = '#ccc';
    });

    dropArea.addEventListener('drop', (event) => {
        event.preventDefault();
        dropArea.style.borderColor = '#ccc';
        handleFiles(event.dataTransfer.files);
    });

    function handleFiles(files) {
        const fileList = Array.from(files).map(file => {
            return {
                name: file.name,
                size: file.size,
                type: file.type,
            };
        });

        console.log(JSON.stringify(fileList));
		formPopup.classList.add(formPopupToggleClass);
    }
})();

(() => {
	const jsOpenMenuBtns = document.querySelectorAll('.js-open-menu');
	const jsCloseMenuBtns = document.querySelectorAll('.js-close-menu');
	const sidebarMenu = document.getElementById('sidebar-menu');

	if ( jsOpenMenuBtns.length ) {
		jsOpenMenuBtns.forEach((btns) => {
			btns.addEventListener('click', (e) => {
				e.preventDefault();

				sidebarMenu.classList.add('!right-0');
			})
		})
	}

	if ( jsCloseMenuBtns.length ) {
		jsCloseMenuBtns.forEach((btns) => {
			btns.addEventListener('click', (e) => {
				e.preventDefault();

				sidebarMenu.classList.remove('!right-0');
			})
		})
	}
})();

(() => {
	document.addEventListener('DOMContentLoaded', function() {
    const fileInput = document.getElementById('files');
    const attachmentsContainer = document.querySelector('.form-popup-attachments');

    fileInput.addEventListener('change', function() {
        // Czyścimy wcześniej załadowane pliki
        attachmentsContainer.innerHTML = '';

        // Pobieramy załadowane pliki
        const files = fileInput.files;

        // Sprawdzamy, czy są jakieś pliki
        if (files.length > 0) {
            const fileList = document.createElement('ul'); // Tworzymy listę plików
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                
                // Tworzymy element listy dla każdego pliku
                const listItem = document.createElement('li');
                listItem.textContent = file.name; // Wyświetlamy nazwę pliku

                // Dodajemy element listy do kontenera
                fileList.appendChild(listItem);
            }
            attachmentsContainer.appendChild(fileList);
        } else {
            // Gdy nie ma plików, wyświetlamy odpowiedni komunikat
            attachmentsContainer.textContent = 'Załącz pliki do wiadomości';
        }
    });
});

})();

(() => {
	const cukrowniaZninVideoPopup = document.querySelector('#cukrownia-znin-video-popup');
	const cukrowniaZninVideo = cukrowniaZninVideoPopup.querySelector('video');
	const toggleClass = 'active';

	const cukrowniaZninOpenBtns = document.querySelectorAll('.js-open-znin-video-popup');
	const cukrowniaZninCloseBtns = document.querySelectorAll('.js-close-znin-video-popup');

	cukrowniaZninOpenBtns.forEach((btn) => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();

			cukrowniaZninVideoPopup.classList.add(toggleClass);

			setTimeout(() => {
				cukrowniaZninVideo.play();
			}, 500);
		});
	});

	cukrowniaZninCloseBtns.forEach((btn) => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();

			cukrowniaZninVideoPopup.classList.remove(toggleClass);

			cukrowniaZninVideo.pause();
		});
	});
})();